<template>
  <form class="form">
    <fieldset class="form__fieldset">
      <div class="row">
        <div class="col-12">
          <FveText
            label="Название"
            name="name"
            required
            v-model="form.name"
          />
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-3">
              <FveText
                label="Количество тренировок"
                name="name"
                required
                v-model="form.trainings"
              />
            </div>
            <div class="col-3">
              <FveText
                label="Количество тренирующихся"
                name="name"
                required
                v-model="form.capacityFix"
              />
            </div>
            <div class="col-3">
              <FveText
                label="Продолжительность"
                name="name"
                required
                v-model="form.length"
              />
            </div>
            <div class="col-3">
              <FveText
                label="Стоимость"
                name="name"
                required
                v-model="form.price"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-4">
          <FveSelectLine
            :option-list="ageList"
            :option-get-key="(obj)=>{ return obj.key.toString(); }"
            :option-get-name="(obj)=>{ return obj.value.toString(); }"
            label="Формат тренировок"
            name="age"
            required
            v-model="form.age"
          />
        </div>
        <div class="col-4">
          <FveSelectLine
            label="Тип тренировок"
            name="type"
            required
            v-model="form.type"
            :option-list="trainerTypeList"
            :option-get-key="(obj)=>{ return obj.key.toString(); }"
            :option-get-name="(obj)=>{ return obj.value.toString(); }"
          />
        </div>
      </div>
      <div class="row">
        <!--
        <div class="col-12">
          <FveSelectAjaxV2Club
            label="Клуб"
            name="club"
            v-model="form.club"
          />
        </div>
        -->

        <div class="col-12">
          <FveSelectAjaxV2Place
            label="Площадка"
            name="court"
            required
            v-model="form.court"
          />
        </div>
        <div class="col-12 mb-3" v-if="form.court && form.court.club">
          <FveCheckbox
            label="Прикрепить тариф к клубу"
            v-model="form.clubAdd"
          />
        </div>

        <div class="col-12">
          <FveSelectAjaxV2Trainer
            label="Тренер"
            name="trainer"
            required
            v-model="form.trainer"
          />
        </div>

        <div class="col-12">
          <FveSelectObject
            label="Направление"
            name="tag"
            required
            v-model="form.tag"
            :option-list="tagList"
            :option-get-key="(option) => option.tag"
            :option-get-name="(option) => option.tag"
          />
        </div>

      </div>
    </fieldset>
  </form>
</template>

<script>

import FveText from "@widgetFormValidate/src/Element/Text/FveText";
import FveCheckbox from "@widgetFormValidate/src/Element/Checkbox/FveCheckbox";
import FveTextarea from "@widgetFormValidate/src/Element/Text/FveTextarea";
import FveFileImagePreview from "@widgetFormValidate/src/Element/Text/FveFileImagePreview";

//
import FveFormMixin           from "@widgetFormValidate/src/Mixin/FveFormMixin";
import FveSelectLine          from "@widgetFormValidate/src/Element/Select/FveSelectLine";
// import FveSelectAjaxV2Club    from "@widgetFormValidate/src/Element/Custom/FveSelectAjaxV2Club";
import FveSelectAjaxV2Place   from "@widgetFormValidate/src/Element/Custom/FveSelectAjaxV2Place";
import FveSelectAjaxV2Trainer from "@widgetFormValidate/src/Element/Custom/FveSelectAjaxV2Trainer";
import FveSelectObject        from "@widgetFormValidate/src/Element/Select/vue-multiselect/FveSelectObject";

export default {
  mixins: [
    FveFormMixin
  ],
  components: {
    FveText,
    FveCheckbox,
    FveSelectLine,
    // FveSelectAjaxV2Club,
    FveSelectAjaxV2Place,
    FveSelectAjaxV2Trainer,
    FveSelectObject,
  },
  data(){
    return {
      ageList: [],
      trainerTypeList: [],
    };
  },
  methods: {
    formSchema() {
      return {
        name        : { type: String, default: () => { return ''; } },
        trainings   : { type: Number, default: () => { return ''; } },
        length      : { type: Number, default: () => { return ''; } },
        price       : { type: Number, default: () => { return ''; } },
        capacityFix : { type: Number, default: () => { return ''; } },

        type       : { type: String, default: () => { return ''; } },
        age        : { type: String, default: () => { return ''; } },
        clubAdd    : { type: Boolean, default: () => { return true; } },

        // club       : { type: Object, default: () => { return null; } },
        court      : { type: Object, default: () => { return null; } },
        trainer    : { type: Object, default: () => { return null; } },
        tag        : { type: Object, default: () => { return null; } },
      };
    },
  },
  computed: {
    tagList() {
      return (this.form.trainer && this.form.trainer.tags ) ? this.form.trainer.tags : [];
    }
  },
  mounted() {
    // RequestManager.Reference.getTagList({}).then((tagObject) => {
    //   this.tagList = tagObject.results;
    // });
    RequestManager.Reference.getClubAgeList({}).then((list) => {
      this.ageList = list;
    });
    RequestManager.Reference.getTrainGroupTypeList({}).then((list) => {
      this.trainerTypeList = list;
    });
  }
};

</script>

<style lang="scss" scoped>

</style>
