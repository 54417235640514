<template>
  <div class="container">
    <div class="page-header">
      <div class="page-header__ell">
        <h1 class="page-title">Добавить тариф</h1>
      </div>
      <!--<div class="page-header__ell">-->
      <!--  <b-form-checkbox v-model="checked" name="check-button" size="lg" switch>-->
      <!--    Включить / отключить-->
      <!--  </b-form-checkbox>-->
      <!--</div>-->
    </div>
    <TariffAddForm ref="tariffAddForm" />
    <form class="form">
      <div class="form__btn-gp">
        <b-button size="lg" variant="danger" @click="save">Сохранить</b-button>
        <router-link :to="{name: this.$routeName.TARIFF_LIST}" class="btn btn-link btn-lg">Отмена</router-link>
      </div>
    </form>
  </div>
</template>

<script>

import TariffAddForm from "@component/Form/TariffAddForm";

export default {
  name: "TariffAdd",
  components: {
    TariffAddForm,
  },
  data() {
    return {
      checked: false,
    };
  },
  methods: {

    async save() {

      let formData = this.$refs.tariffAddForm.formSubmitGetData();
      if(!formData) { return; }

      let sendData = Object.assign({}, formData);

      // if(sendData.club) {
      //   sendData.club    = sendData.club.id;
      // } else {
      //   delete sendData.club;
      // }
      sendData.court   = sendData.court.id;
      sendData.trainer = sendData.trainer.id;
      sendData.capacity = parseInt(sendData.capacityFix) + 1;

      /*
      // for test
      let sendData = {
        club: 1,   // {id: 1, created_at: "2021-03-23",…}
        court: 2, // {id: 2, location: {latitude: 39.234, longitude: 56.342}, club_name: null, courtmedia_set: [],…}
        lifetime: "60",
        name: "test",
        price: "1000",
        tag: {tag: "Dad dad"},
        trainer: 64, // {id: 64, username: "+79667854568", first_name: "Кирилл", last_name: "Тренер", middle_name: "",…}
        trainings: "1",
        type: "PRS",
      };
      */

      if(formData.court.club && formData.clubAdd) {
        RequestManager.Club.addClubTariff({
          tariff: sendData,
          clubId: formData.court.club,
        }).then( (res) => {
          this.$toasts.push({
            message: 'Тариф успешно добавлен',
            duration: 4000,
          });
          this.$router.push({name: this.$routeName.TARIFF_LIST });
        });
      } else {
        RequestManager.Tariff.addTariff({tariff: sendData}).then( (res) => {
          this.$toasts.push({
            message: 'Тариф успешно добавлен',
            duration: 4000,
          });
          this.$router.push({name: this.$routeName.TARIFF_LIST });
        });
      }

    }
  }
};
</script>

<style lang="scss" scoped>

</style>
