<script>

import FveSelectAjaxV2Mixin from "@widgetFormValidate/src/Element/Custom/FveSelectAjaxV2Mixin";

export default {
  mixins: [
    FveSelectAjaxV2Mixin
  ],
  methods: {
    getItemId(item) {
      return item.id;
    },
    getItemName(item) {
      return (item && item.name) ? item.name + (item.club_name ? (' - ' + item.club_name) : '' ) : '';
    },
    request(query) {
      return RequestManager.Place.getPlaceList({limit:20, search: query}).then((response) => {
        return response.results;
      });
    },
  },
  mounted() {
    this.asyncFind('', -1);
  }
};
</script>
